import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import jkVideo from "../../../assets/JK.mp4";

const useStyles = makeStyles((theme) => ({
  mottoSection: {
    width: "100%",
    padding: 0,
    marginTop: "20%",
    height: 710,

    [theme.breakpoints.down("md")]: {
      height: 420,
    },
  },

  videoContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },

  video: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -2,
  },
  videoLayer: {
    backgroundColor: "black",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    opacity: 0.3,
    zIndex: -1,
  },
  mottoContainer: {},

  mottoTitle: {
    fontWeight: "bold",
    lineHeight: 1.5,
    fontSize: (props) => (props.language === "en" ? 38 : 44),
    color: "white",

    [theme.breakpoints.down("md")]: {
      fontSize: (props) => (props.language === "en" ? 24 : 24),
    },
  },
}));

const HomeMotto = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();

  return (
    <Container maxWidth="xl" className={classes.mottoSection}>
      <Box className={classes.videoContainer}>
        <video className={classes.video} autoPlay loop muted playsInline>
          <source src={jkVideo} type="video/mp4" />
        </video>
        <Box className={classes.videoLayer} />
        <Container maxWidth="lg">
          <Typography className={classes.mottoTitle}>
            {t("home.motto.title1")}
          </Typography>
        </Container>
      </Box>
    </Container>
  );
};

export default HomeMotto;
