import { Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import HomeContact from "../../home/components/HomeContact";

import directions from "../../../assets/directions.png";
import directions2x from "../../../assets/directions@2x.png";
import directions3x from "../../../assets/directions@3x.png";
import directionsEn from "../../../assets/directions_en.png";
import directionsEn2x from "../../../assets/directions_en@2x.png";
import directionsEn3x from "../../../assets/directions_en@3x.png";
import directionsKo from "../../../assets/directions_ko.png";
import directionsKo2x from "../../../assets/directions_ko@2x.png";
import directionsKo3x from "../../../assets/directions_ko@3x.png";
import directionsZh from "../../../assets/directions_zh.png";
import directionsZh2x from "../../../assets/directions_zh@2x.png";
import directionsZh3x from "../../../assets/directions_zh@3x.png";
import SectionTitleImage from "../../common/components/SectionTitleImage";
import { useTranslation } from "react-i18next";

const DIRECTION_TITLE = {
  en: {
    src: directionsEn,
    srcSet: `${directionsEn2x} 2x, ${directionsEn3x} 3x`,
  },
  ko: {
    src: directionsKo,
    srcSet: `${directionsKo2x} 2x, ${directionsKo3x} 3x`,
  },
  zh: {
    src: directionsZh,
    srcSet: `${directionsZh2x} 2x, ${directionsZh3x} 3x`,
  },
  ja: {
    src: directions,
    srcSet: `${directions2x} 2x, ${directions3x} 3x`,
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 30,
    marginBottom: 60,
    width: "100%",
  },
}));

const Directions = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <HomeContact
      showTitle={false}
      title={
        <Box className={classes.title}>
          <SectionTitleImage
            src={DIRECTION_TITLE[i18n.languages[0]].src}
            srcSet={DIRECTION_TITLE[i18n.languages[0]].srcSet}
          />
        </Box>
      }
      showMoreButton={false}
    />
  );
};

export default Directions;
