import { Box, Container } from "@material-ui/core";
import React from "react";

const Footer = () => {
  return (
    <Container
      maxWidth="xl"
      style={{
        backgroundColor: "#20294b",
        textAlign: "center",
        paddingTop: 30,
        paddingBottom: 30,
      }}
    >
      <Box
        style={{
          fontSize: 18,
          lineHeight: 1.3,
          letterSpacing: -0.68,
          color: "white",
        }}
      >
        Copyright © JK All rights reserved.
      </Box>
    </Container>
  );
};

export default Footer;
