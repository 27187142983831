import React, { Suspense, useEffect, useState } from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./modules/home/components/Home";
import Navigation from "./modules/navigation/components/Navigation";
import { useTranslation } from "react-i18next";
import { FONT_FAMILY, LANGUAGES } from "./modules/utils/constants";
import {
  Container,
  createMuiTheme,
  Fab,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import Footer from "./modules/footer/components/Footer";
import Introduction from "./modules/introduction/components/Introduction";
import Directions from "./modules/directions/components/Directions";
import Retail from "./modules/retail/components/Retail";
import Planning from "./modules/planning/components/Planning";
import ScrollToTop from "./modules/utils/ScrollToTop";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { iOS } from "./modules/utils/detectIOS";

function App(props) {
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  const fontFamily = FONT_FAMILY[i18n.languages[0]];

  const theme = createMuiTheme({
    typography: {
      fontFamily,
    },
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePressWhenDrawOpen = (e) => {
    if (open) {
      console.log(e);
      e.preventDefault();
      setOpen(false);
    }
  };

  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <Router>
        <div
          onClick={handlePressWhenDrawOpen}
          style={{
            wordBreak:
              i18n.languages[0] === LANGUAGES.ja ||
              i18n.languages[0] === LANGUAGES.zh
                ? "break-all"
                : "keep-all",
          }}
        >
          <Navigation
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
          <Container maxWidth="xl" style={{ padding: 0 }} className="App">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about">
                <Introduction />
              </Route>
              <Route path="/planning">
                <Planning />
              </Route>
              <Route path="/retail">
                <Retail />
              </Route>
              <Route path="/directions">
                <Directions />
              </Route>
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
      {!iOS() && (
        <ScrollToTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
      )}
    </ThemeProvider>
  );
}

export default App;
