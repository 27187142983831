import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import Carousel from "@brainhubeu/react-carousel";

import topBackground from "../../../assets/planning_background.png";
import SectionTitleImage from "../../common/components/SectionTitleImage";

import planningTitle from "../../../assets/planning_title.png";
import planningTitle2x from "../../../assets/planning_title@2x.png";
import planningTitle3x from "../../../assets/planning_title@3x.png";
import planningTitleEn from "../../../assets/planning_title_en.png";
import planningTitleEn2x from "../../../assets/planning_title_en@2x.png";
import planningTitleEn3x from "../../../assets/planning_title_en@3x.png";
import planningTitleKo from "../../../assets/planning_title_ko.png";
import planningTitleKo2x from "../../../assets/planning_title_ko@2x.png";
import planningTitleKo3x from "../../../assets/planning_title_ko@3x.png";
import planningTitleZh from "../../../assets/planning_title_zh.png";
import planningTitleZh2x from "../../../assets/planning_title_zh@2x.png";
import planningTitleZh3x from "../../../assets/planning_title_zh@3x.png";

import planning1 from "../../../assets/planning1.png";
import planning12x from "../../../assets/planning1@2x.png";
import planning13x from "../../../assets/planning1@3x.png";
import planning2 from "../../../assets/planning2.png";
import planning22x from "../../../assets/planning2@2x.png";
import planning23x from "../../../assets/planning2@3x.png";
import planning3 from "../../../assets/planning3.png";
import planning32x from "../../../assets/planning3@2x.png";
import planning33x from "../../../assets/planning3@3x.png";
import planning4 from "../../../assets/planning4.png";
import planning42x from "../../../assets/planning4@2x.png";
import planning43x from "../../../assets/planning4@3x.png";
import planning5 from "../../../assets/planning5.png";
import planning52x from "../../../assets/planning5@2x.png";
import planning53x from "../../../assets/planning5@3x.png";
import planning6 from "../../../assets/planning6.png";
import planning62x from "../../../assets/planning6@2x.png";
import planning63x from "../../../assets/planning6@3x.png";

import design from "../../../assets/design.png";
import design2x from "../../../assets/design@2x.png";
import design3x from "../../../assets/design@3x.png";
import pattern1 from "../../../assets/pattern1.png";
import pattern12x from "../../../assets/pattern1@2x.png";
import pattern13x from "../../../assets/pattern1@3x.png";
import pattern2 from "../../../assets/pattern2.png";
import pattern22x from "../../../assets/pattern2@2x.png";
import pattern23x from "../../../assets/pattern2@3x.png";
import sewing from "../../../assets/sewing.png";

import clothesTitle from "../../../assets/clothes_title.png";
import clothesTitle2x from "../../../assets/clothes_title@2x.png";
import clothesTitle3x from "../../../assets/clothes_title@3x.png";
import clothesTitleEn from "../../../assets/clothes_title_en.png";
import clothesTitleEn2x from "../../../assets/clothes_title_en@2x.png";
import clothesTitleEn3x from "../../../assets/clothes_title_en@3x.png";
import clothesTitleKo from "../../../assets/clothes_title_ko.png";
import clothesTitleKo2x from "../../../assets/clothes_title_ko@2x.png";
import clothesTitleKo3x from "../../../assets/clothes_title_ko@3x.png";
import clothesTitleZh from "../../../assets/clothes_title_zh.png";
import clothesTitleZh2x from "../../../assets/clothes_title_zh@2x.png";
import clothesTitleZh3x from "../../../assets/clothes_title_zh@3x.png";

import clothes1 from "../../../assets/clothes1.png";
import clothes12x from "../../../assets/clothes1@2x.png";
import clothes2 from "../../../assets/clothes2.png";
import clothes22x from "../../../assets/clothes2@2x.png";
import clothes3 from "../../../assets/clothes3.png";
import clothes32x from "../../../assets/clothes3@2x.png";

import goodsTitle from "../../../assets/goods_title.png";
import goodsTitle2x from "../../../assets/goods_title@2x.png";
import goodsTitle3x from "../../../assets/goods_title@3x.png";
import goodsTitleEn from "../../../assets/goods_title_en.png";
import goodsTitleEn2x from "../../../assets/goods_title_en@2x.png";
import goodsTitleEn3x from "../../../assets/goods_title_en@3x.png";
import goodsTitleKo from "../../../assets/goods_title_ko.png";
import goodsTitleKo2x from "../../../assets/goods_title_ko@2x.png";
import goodsTitleKo3x from "../../../assets/goods_title_ko@3x.png";
import goodsTitleZh from "../../../assets/goods_title_zh.png";
import goodsTitleZh2x from "../../../assets/goods_title_zh@2x.png";
import goodsTitleZh3x from "../../../assets/goods_title_zh@3x.png";

import goods1 from "../../../assets/goods1.png";
import goods2 from "../../../assets/goods2.png";
import goods3 from "../../../assets/goods3.png";
import goods4 from "../../../assets/goods4.png";

import arrowRight from "../../../assets/arrow_right.png";
import arrowLeft from "../../../assets/arrow_left.png";

const GOODS_IMAGES = [goods1, goods2, goods3, goods4];

const PLANNING_IMAGES = [
  {
    src: planning1,
    srcSet: `${planning12x} 2x, ${planning13x} 3x`,
  },
  {
    src: planning2,
    srcSet: `${planning22x} 2x, ${planning23x} 3x`,
  },
  {
    src: planning3,
    srcSet: `${planning32x} 2x, ${planning33x} 3x`,
  },
  {
    src: planning4,
    srcSet: `${planning42x} 2x, ${planning43x} 3x`,
  },
  {
    src: planning5,
    srcSet: `${planning52x} 2x, ${planning53x} 3x`,
  },
  {
    src: planning6,
    srcSet: `${planning62x} 2x, ${planning63x} 3x`,
  },
];

const PLANNING_TITLE = {
  en: {
    src: planningTitleEn,
    srcSet: `${planningTitleEn2x} 2x, ${planningTitleEn3x} 3x`,
  },

  ko: {
    src: planningTitleKo,
    srcSet: `${planningTitleKo2x} 2x, ${planningTitleKo3x} 3x`,
  },
  zh: {
    src: planningTitleZh,
    srcSet: `${planningTitleZh2x} 2x, ${planningTitleZh3x} 3x`,
  },
  ja: {
    src: planningTitle,
    srcSet: `${planningTitle2x} 2x, ${planningTitle3x} 3x`,
  },
};

const CLOTHES_TITLE = {
  en: {
    src: clothesTitleEn,
    srcSet: `${clothesTitleEn2x} 2x, ${clothesTitleEn3x} 3x`,
  },

  ko: {
    src: clothesTitleKo,
    srcSet: `${clothesTitleKo2x} 2x, ${clothesTitleKo3x} 3x`,
  },
  zh: {
    src: clothesTitleZh,
    srcSet: `${clothesTitleZh2x} 2x, ${clothesTitleZh3x} 3x`,
  },
  ja: {
    src: clothesTitle,
    srcSet: `${clothesTitle2x} 2x, ${clothesTitle3x} 3x`,
  },
};

const GOODS_TITLE = {
  en: {
    src: goodsTitleEn,
    srcSet: `${goodsTitleEn2x} 2x, ${goodsTitleEn3x} 3x`,
  },

  ko: {
    src: goodsTitleKo,
    srcSet: `${goodsTitleKo2x} 2x, ${goodsTitleKo3x} 3x`,
  },
  zh: {
    src: goodsTitleZh,
    srcSet: `${goodsTitleZh2x} 2x, ${goodsTitleZh3x} 3x`,
  },
  ja: {
    src: goodsTitle,
    srcSet: `${goodsTitle2x} 2x, ${goodsTitle3x} 3x`,
  },
};

const useStyles = makeStyles((theme) => ({
  topTitleSection: {
    position: "relative",
    paddingTop: "10%",
    paddingBottom: "10%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: "15%",
      paddingBottom: "15%",
    },
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  mainTitle: {
    fontSize: 42,
    color: "#2441ab",
    marginBottom: 10,
    fontWeight: "600",
  },

  planningTitleSection: {
    paddingTop: "5%",
    paddingBottom: "5%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "15%",
      paddingBottom: "15%",
    },
  },

  planningTitle: {
    marginBottom: 60,
  },

  bigBodyText: {
    fontSize: 20,
    lineHeight: 2.1,
    color: "black",
    textAlign: "left",
  },

  section: {
    paddingTop: "5%",
    paddingBottom: "5%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "15%",
      paddingBottom: "15%",
    },
  },

  title: {
    fontSize: 43,
    lineHeight: 1.2,
    color: "#2441ab",
    fontWeight: "bold",
    marginBottom: 90,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      marginBottom: 60,
    },
  },

  imageGridContainer: { marginBottom: 85 },

  arrowButton: {
    width: 60,
    height: 60,
    [theme.breakpoints.down("md")]: {
      width: 30,
      height: 30,
    },
  },
}));

const Planning = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Container maxWidth="xl" className={classes.topTitleSection}>
        <img src={topBackground} className={classes.backgroundImage} />
        <Box>
          <Typography className={classes.mainTitle}>
            {t("planning.mainTitle")}
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="md" className={classes.planningTitleSection}>
        <SectionTitleImage
          src={PLANNING_TITLE[i18n.languages[0]].src}
          srcSet={PLANNING_TITLE[i18n.languages[0]].srcSet}
          containerClass={classes.planningTitle}
        />
        <Container maxWidth="md">
          <Box>
            <Typography className={classes.bigBodyText}>
              {t("planning.planningMainBody")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container
        maxWidth="xl"
        className={classes.section}
        style={{ backgroundColor: "#f9fbfb", paddingLeft: 0, paddingRight: 0 }}
      >
        <Box>
          <Typography className={classes.title}>
            {t("planning.planningTitle")}
          </Typography>
        </Box>

        <Container>
          <Grid container spacing={!matches ? 6 : 4}>
            {PLANNING_IMAGES.map((set, index) => (
              <Grid key={set.src} item xs={6} md={4}>
                <img
                  src={set.src}
                  srcSet={set.srcSet}
                  style={{ width: !matches ? "60%" : "100%", marginBottom: 20 }}
                />
                <Typography
                  className={classes.bigBodyText}
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    lineHeight: 1.3,
                    fontWeight: "bold",
                  }}
                >
                  {t(`planning.planningBody${index + 1}`)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>

      <Container maxWidth="md" className={classes.section}>
        <Box>
          <Typography className={classes.title}>
            {t("planning.designTitle")}
          </Typography>
        </Box>
        <img
          src={design}
          srcSet={`${design2x} 2x, ${design3x} 3x`}
          style={{
            width: "100%",
            objectFit: "cover",
            marginBottom: !matches ? 60 : 40,
          }}
        />

        <Container maxWidth="md">
          <Box>
            <Typography className={classes.bigBodyText}>
              {t("planning.designBody")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container className={classes.section}>
        <Box>
          <Typography className={classes.title}>
            {t("planning.patternTitle")}
          </Typography>
        </Box>
        <Grid
          container
          spacing={!matches ? 2 : 1}
          className={classes.imageGridContainer}
          style={{ marginBottom: !matches ? 60 : 40 }}
        >
          <Grid item xs={12} md={6}>
            <img
              src={pattern1}
              srcSet={`${pattern12x} 2x, ${pattern13x} 3x`}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={pattern2}
              srcSet={`${pattern22x} 2x, ${pattern23x} 3x`}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Grid>
        </Grid>
        <Container maxWidth="md">
          <Box>
            <Typography className={classes.bigBodyText}>
              {t("planning.patternBody")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container className={classes.section}>
        <Box>
          <Typography className={classes.title}>
            {t("planning.sewingTitle")}
          </Typography>
        </Box>
        <img
          src={sewing}
          style={{
            width: "100%",
            objectFit: "cover",
            marginBottom: !matches ? 60 : 40,
          }}
        />
        <Container maxWidth="md">
          <Box>
            <Typography className={classes.bigBodyText}>
              {t("planning.sewingBody")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container className={classes.section}>
        <SectionTitleImage
          src={CLOTHES_TITLE[i18n.languages[0]].src}
          srcSet={CLOTHES_TITLE[i18n.languages[0]].srcSet}
          containerClass={classes.planningTitle}
        />
        <Container maxWidth="md">
          <Box m={2}>
            <img
              src={clothes1}
              srcSet={`${clothes12x} 2x`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box m={2}>
            <img
              src={clothes2}
              srcSet={`${clothes22x} 2x`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box m={2}>
            <img
              src={clothes3}
              srcSet={`${clothes32x} 2x`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Container>
        <Container maxWidth="md">
          <Box>
            <Typography
              className={classes.bigBodyText}
              style={{ marginTop: !matches ? 60 : 40 }}
            >
              {t("planning.clothesBody")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container className={classes.section}>
        <SectionTitleImage
          src={GOODS_TITLE[i18n.languages[0]].src}
          srcSet={GOODS_TITLE[i18n.languages[0]].srcSet}
          containerClass={classes.planningTitle}
        />
        <Container maxWidth="md">
          <Box m={2} style={{ marginBottom: !matches ? 60 : 40 }}>
            <Carousel
              arrows={!matches}
              slidesPerPage={1}
              infinite
              addArrowClickHandler
              arrowRight={
                !matches && (
                  <Button>
                    <img src={arrowRight} className={classes.arrowButton} />
                  </Button>
                )
              }
              arrowLeft={
                !matches && (
                  <Button>
                    <img src={arrowLeft} className={classes.arrowButton} />
                  </Button>
                )
              }
            >
              {GOODS_IMAGES.map((image) => (
                <img
                  key={image}
                  src={image}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ))}
            </Carousel>
          </Box>
        </Container>

        <Container maxWidth="md">
          <Box>
            <Typography
              className={classes.bigBodyText}
              style={{ marginTop: !matches ? 60 : 40, marginBottom: 60 }}
            >
              {t("planning.goodsBody")}
            </Typography>
          </Box>
        </Container>
      </Container>
    </>
  );
};

export default Planning;
