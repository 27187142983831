import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { autoplayPlugin } from "@brainhubeu/react-carousel";

import SectionTitleImage from "../../common/components/SectionTitleImage";

import retailA1 from "../../../assets/retailA1.png";
import retailA2 from "../../../assets/retailA2.png";
import retailA3 from "../../../assets/retailA3.png";
import retailA4 from "../../../assets/retailA4.png";
import retailA5 from "../../../assets/retailA5.png";
import retailB2 from "../../../assets/retailB2.png";

import retailB1 from "../../../assets/retailB1.png";

import giji1 from "../../../assets/giji1.png";
import giji2 from "../../../assets/giji2.png";
import giji3 from "../../../assets/giji3.png";
import giji4 from "../../../assets/giji4.png";
import giji5 from "../../../assets/giji5.png";
import giji6 from "../../../assets/giji6.png";
import giji7 from "../../../assets/giji7.png";

import background from "../../../assets/retail.png";

import preOwnedTitle from "../../../assets/pre_owned_title.png";
import preOwnedTitle2x from "../../../assets/pre_owned_title@2x.png";
import preOwnedTitle3x from "../../../assets/pre_owned_title@3x.png";
import preOwnedTitleKo from "../../../assets/pre_owned_title_ko.png";
import preOwnedTitleKo2x from "../../../assets/pre_owned_title_ko@2x.png";
import preOwnedTitleKo3x from "../../../assets/pre_owned_title_ko@3x.png";
import preOwnedTitleEn from "../../../assets/pre_owned_title_en.png";
import preOwnedTitleEn2x from "../../../assets/pre_owned_title_en@2x.png";
import preOwnedTitleEn3x from "../../../assets/pre_owned_title_en@3x.png";
import preOwnedTitleZh from "../../../assets/pre_owned_title_zh.png";
import preOwnedTitleZh2x from "../../../assets/pre_owned_title_zh@2x.png";
import preOwnedTitleZh3x from "../../../assets/pre_owned_title_zh@3x.png";

import preOwned from "../../../assets/pre_owned.png";

import leatherBackground from "../../../assets/leather_background.png";

import arrowRight from "../../../assets/arrow_right.png";
import arrowLeft from "../../../assets/arrow_left.png";

const RETAIL_A = [retailB2, retailA1, retailA2, retailA3, retailA4, retailA5];

const FABRICS = [giji1, giji2, giji3, giji4, giji5, giji6, giji7];

const PRE_OWNED_TITLE = {
  en: {
    src: preOwnedTitleEn,
    srcSet: `${preOwnedTitleEn2x} 2x, ${preOwnedTitleEn3x} 3x`,
  },
  ko: {
    src: preOwnedTitleKo,
    srcSet: `${preOwnedTitleKo2x} 2x, ${preOwnedTitleKo3x} 3x`,
  },
  zh: {
    src: preOwnedTitleZh,
    srcSet: `${preOwnedTitleZh2x} 2x, ${preOwnedTitleZh3x} 3x`,
  },
  ja: {
    src: preOwnedTitle,
    srcSet: `${preOwnedTitle2x} 2x, ${preOwnedTitle3x} 3x`,
  },
};

const useStyles = makeStyles((theme) => ({
  retailMainTitle: {
    position: "relative",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "10%",
    paddingBottom: "10%",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: "15%",
      paddingBottom: "15%",
    },
  },
  backgroundImg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  mainTitle: {
    fontSize: 42,
    color: "#2441ab",
    marginBottom: 10,
    fontWeight: "600",
  },
  mainSubtitle: {
    fontSize: 26,
    color: "#2441ab",
    marginBottom: 10,
    fontWeight: "600",
  },

  mainBodySection: {
    paddingTop: "10%",
    paddingBottom: "10%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "20%",
      paddingBottom: "20%",
    },
  },

  mainBodyContainer: {
    width: "50%",
    margin: "0px auto",
    marginBottom: "10%",

    [theme.breakpoints.down("md")]: {
      width: "80%",
      marginBottom: "20%",
    },
  },

  mainBody: {
    fontSize: 20,
    lineHeight: 2.1,
    textAlign: "left",
    color: "black",
  },

  arrowButton: {
    width: 60,
    height: 60,
    [theme.breakpoints.down("md")]: {
      width: 30,
      height: 30,
    },
  },

  leatherSection: {
    position: "relative",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "5%",
    paddingBottom: "5%",
    height: "max-content",
  },
  leatherBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  leatherContainer: {
    backgroundColor: "white",
    paddingTop: 60,
    paddingBottom: 60,
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.down("md")]: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    fontSize: 43,
    lineHeight: 1.2,
    color: "#2441ab",
    fontWeight: "bold",
    marginBottom: 70,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      marginBottom: 40,
    },
  },
  body: {
    fontSize: 20,
    lineHeight: 2.1,
    color: "black",
    textAlign: "left",
  },

  preOwnedSection: {
    position: "relative",

    paddingTop: "10%",
    paddingBottom: "10%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "20%",
      paddingBottom: "20%",
    },
  },

  preOwnedContainer: { marginTop: 60, position: "relative" },
  preOwnedTextContainer: {
    backgroundColor: "white",
    position: "absolute",
    left: "50%",
    bottom: 0,
    transform: "translate(-50%,50%)",
    width: "70%",
    paddingTop: 70,
    paddingBottom: 70,
    paddingLeft: 80,
    paddingRight: 80,
  },
}));

const Retail = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [aIndex, setAIndex] = useState(0);

  const [bIndex, setBIndex] = useState(0);

  return (
    <>
      <Container maxWidth="xl" className={classes.retailMainTitle}>
        <img src={background} className={classes.backgroundImg} />
        <Container>
          <Box>
            <Typography className={classes.mainTitle}>
              {t("retail.mainTitle")}
            </Typography>
            <Typography className={classes.mainSubtitle}>
              {t("retail.mainSubtitle")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container className={classes.mainBodySection}>
        <Box className={classes.mainBodyContainer}>
          <Typography className={classes.mainBody}>
            {t("retail.mainBody")}
          </Typography>
        </Box>
        <Container maxWidth="md">
          <Box style={{ marginBottom: !matches ? 60 : 40 }}>
            <img
              src={retailB1}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        </Container>
        <Container maxWidth="md">
          <Typography className={classes.title}>
            {t("retail.retailTitle1")}
          </Typography>
          <Typography className={classes.body}>
            {t("retail.retailBody1")}
          </Typography>
        </Container>
        <Box style={{ marginTop: 120, marginBottom: !matches ? 60 : 40 }}>
          <Carousel
            autoPlay={5000}
            animationSpeed={1000}
            arrows={!matches}
            slidesPerPage={1}
            infinite
            addArrowClickHandler
            arrowRight={
              !matches && (
                <Button>
                  <img src={arrowRight} className={classes.arrowButton} />
                </Button>
              )
            }
            arrowLeft={
              !matches && (
                <Button>
                  <img src={arrowLeft} className={classes.arrowButton} />
                </Button>
              )
            }
          >
            {RETAIL_A.map((image) => (
              <img
                key={image}
                src={image}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ))}
          </Carousel>
        </Box>
        <Container maxWidth="md">
          <Typography className={classes.title}>
            {t("retail.retailTitle2")}
          </Typography>
          <Typography className={classes.body}>
            {t("retail.retailBody2")}
          </Typography>
        </Container>
        <Box style={{ marginTop: 120, marginBottom: !matches ? 60 : 40 }}>
          <Carousel
            autoPlay={5000}
            animationSpeed={1000}
            arrows={!matches}
            slidesPerPage={1}
            infinite
            addArrowClickHandler
            arrowRight={
              !matches && (
                <Button>
                  <img src={arrowRight} className={classes.arrowButton} />
                </Button>
              )
            }
            arrowLeft={
              !matches && (
                <Button>
                  <img src={arrowLeft} className={classes.arrowButton} />
                </Button>
              )
            }
          >
            {FABRICS.map((image) => (
              <img
                key={image}
                src={image}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ))}
          </Carousel>
        </Box>
        <Container maxWidth="md">
          <Typography className={classes.title}>
            {t("retail.fabricsTitle")}
          </Typography>
          <Typography className={classes.body}>
            {t("retail.fabricsBody")}
          </Typography>
        </Container>
      </Container>

      <Container maxWidth="xl" className={classes.leatherSection}>
        <img src={leatherBackground} className={classes.leatherBackground} />
        <Container maxWidth="md">
          <Box className={classes.leatherContainer}>
            <Typography className={classes.title}>
              {t("retail.leatherTitle")}
            </Typography>
            <Typography className={classes.body}>
              {t("retail.leatherBody")}
            </Typography>
          </Box>
        </Container>
      </Container>

      <Container className={classes.preOwnedSection}>
        <SectionTitleImage
          src={PRE_OWNED_TITLE[i18n.languages[0]].src}
          srcSet={PRE_OWNED_TITLE[i18n.languages[0]].srcSet}
        />
        <Container maxWidth="md">
          <Box className={classes.preOwnedContainer}>
            <img
              src={preOwned}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                marginBottom: !matches ? 60 : 40,
              }}
            />
          </Box>
        </Container>
        <Container maxWidth="md" style={{ marginBottom: 60 }}>
          <Typography className={classes.title}>
            {t("retail.preOwnedTitle")}
          </Typography>
          <Typography className={classes.body}>
            {t("retail.preOwnedBody")}
          </Typography>
        </Container>
      </Container>
    </>
  );
};

export default Retail;
