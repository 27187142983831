import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import SwipeableViews from "react-swipeable-views";

import aboutUs1 from "../../../assets/aboutUs1.png";
import aboutUs2 from "../../../assets/aboutUs2.png";
import aboutUs3 from "../../../assets/aboutUs3.png";
import aboutUs4 from "../../../assets/aboutUs4.png";
import aboutUs5 from "../../../assets/aboutUs5.png";

import { useTranslation } from "react-i18next";
import MoreButton from "../common/MoreButton";

const ABOUT_US_IMAGES = [aboutUs1, aboutUs2, aboutUs3, aboutUs4, aboutUs5];

const useStyles = makeStyles((theme) => ({
  aboutUsSection: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "4%",
    paddingBottom: "4%",
    backgroundColor: "#fcfbff",

    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
  },
  aboutUsContainer: {},
  aboutUsTitle: {
    fontSize: 22,
    lineHeight: 1.1,
    color: "black",
    fontFamily: "Noto Sans",
    letterSpacing: -0.83,
    marginBottom: 30,

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  aboutUsMainTitle: {
    marginBottom: 130,
    [theme.breakpoints.down("md")]: {
      marginBottom: 80,
    },
  },
  aboutUsMainTitleText: {
    fontSize: (props) => (props.language === "en" ? 38 : 42),
    lineHeight: 1.5,
    letterSpacing: -0.25,
    color: "black",
    fontWeight: "bold",

    [theme.breakpoints.down("md")]: {
      fontSize: (props) => (props.language === "en" ? 20 : 24),
    },
  },
  aboutUsBodyText: {
    fontSize: 16,
    lineHeight: 2,
    color: "black",
  },
}));

const HomeAboutUs = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();

  const mathces = useMediaQuery(theme.breakpoints.down("md"));

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIndexChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Container maxWidth="xl" className={classes.aboutUsSection}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.aboutUsContainer}
      >
        <Grid xs={10} item className={classes.aboutUsTitle}>
          <Typography>{t("home.aboutUs.title1")}</Typography>
        </Grid>
        <Grid xs={10} item className={classes.aboutUsMainTitle}>
          <Typography className={classes.aboutUsMainTitleText}>
            {t("home.aboutUs.title2")}
          </Typography>
          <Typography className={classes.aboutUsMainTitleText}>
            {t("home.aboutUs.title3")}
          </Typography>
          <Typography className={classes.aboutUsMainTitleText}>
            {t("home.aboutUs.title4")}
          </Typography>
        </Grid>
      </Grid>
      <Container>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={11} lg={5}>
            <SwipeableViews
              index={currentIndex}
              onChangeIndex={handleIndexChange}
            >
              {ABOUT_US_IMAGES.map((image) => (
                <div key={image} style={{ width: "100%" }}>
                  <img
                    src={image}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </SwipeableViews>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 16,
              }}
            >
              {ABOUT_US_IMAGES.map((image, index) => (
                <div
                  key={`image${index}`}
                  style={{
                    width: 12,
                    height: 12,
                    marginLeft: 12,
                    marginBottom: 12,
                    cursor: "pointer",
                    backgroundColor:
                      index === currentIndex ? "#2441ab" : "white",
                    border:
                      index !== currentIndex ? "1px solid #2441ab" : "none",
                  }}
                  onClick={() => handleIndexChange(index)}
                ></div>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            lg={5}
            style={{
              textAlign: "left",
              justifyContent: "space-between",
            }}
          >
            <Typography className={classes.aboutUsBodyText}>
              {t("home.aboutUs.body1")}
            </Typography>
            <Typography className={classes.aboutUsBodyText}>
              <br />
              {t("home.aboutUs.body2")}
            </Typography>
            <Typography className={classes.aboutUsBodyText}>
              <br />
              {t("home.aboutUs.body3")}
            </Typography>
          </Grid>
        </Grid>
        <MoreButton
          containerStyle={{ marginTop: !mathces ? 80 : 40 }}
          href="/about"
        />
      </Container>
    </Container>
  );
};

export default HomeAboutUs;
