import React, { forwardRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DRAWER_WIDTH, NAV_MENUS } from "../../utils/constants";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "../../../assets/logo.png";
import logo2x from "../../../assets/logo@2x.png";
import logo3x from "../../../assets/logo@3x.png";
import clsx from "clsx";
import Dropdown from "react-dropdown";
import NavigationDrawer from "./NavigationDrawer";

const LANGUAGE_MENUS = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "ja",
    label: "日本語",
  },
  {
    value: "ko",
    label: "한국어",
  },
  {
    value: "zh",
    label: "中文",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
    padding: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      paddingRight: 20,
    },
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: DRAWER_WIDTH,
  },
  toolBar: {
    width: "100%",
    padding: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    borderRadius: 0,
    height: 100,
    padding: 0,

    "&:hover": {
      backgroundColor: "#2441ab",
    },

    [theme.breakpoints.down("sm")]: {
      height: 70,
    },
  },
  logoLabel: {
    width: "100%",
    height: "100%",
  },
  logoImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  navContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nav: {
    fontSize: 16,
    lineHeight: 1.5,
    color: "black",
    marginRight: 4,
    marginLeft: 4,
  },
  navLabel: {
    display: "flex",
    flexDirection: "column",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  languageDropdown: {
    width: 90,
    fontSize: 14,
    paddingRight: 0,
    backgroundColor: "#f6f6f6",
    marginRight: 20,
  },
  dropdownControl: {
    paddingRight: 0,
  },
}));

function Navigation({ open, handleDrawerOpen, handleDrawerClose }) {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();

  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [language, setLanguage] = useState(i18n.languages[0]);

  const handleLanguage = (e) => {
    const { value } = e;
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="static"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          id="back-to-top-anchor"
        >
          <Toolbar className={classes.toolBar}>
            <Button
              href="/"
              classes={{ root: classes.logo, label: classes.logoLabel }}
            >
              <img
                src={logo}
                srcSet={`${logo2x} 2x, ${logo3x} 3x`}
                className={classes.logoImage}
              />
            </Button>
            {matches ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
            ) : (
              <>
                <Box className={classes.navContainer}>
                  {NAV_MENUS.map((menu, index) => {
                    const menuText = t(`nav.${menu}`);

                    const openingIndex = menuText.indexOf("<");
                    const closingIndex = menuText.indexOf(">");

                    let firstLine = menuText;
                    let secondLine;

                    if (openingIndex > 0 && closingIndex > 0) {
                      firstLine = menuText.slice(0, openingIndex);
                      secondLine = menuText.slice(closingIndex + 1);
                    }

                    const href = index === 0 ? "/" : `/${menu}`;

                    return (
                      <Button
                        key={menu}
                        href={href}
                        color="inherit"
                        classes={{
                          root: classes.nav,
                          label: classes.navLabel,
                        }}
                        style={{
                          fontWeight: href === pathname ? "bold" : "normal",
                          color: href === pathname ? "#2441ab" : "black",
                        }}
                      >
                        <div>{firstLine}</div>
                        {secondLine && <div>{secondLine}</div>}
                      </Button>
                    );
                  })}
                </Box>
                <Dropdown
                  options={LANGUAGE_MENUS}
                  value={language}
                  className={classes.languageDropdown}
                  controlClassName={classes.dropdownControl}
                  onChange={handleLanguage}
                />
              </>
            )}
          </Toolbar>
        </AppBar>
      </div>
      <NavigationDrawer open={open} handleDrawerClose={handleDrawerClose} />
    </>
  );
}

export default Navigation;
