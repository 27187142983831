export const LANGUAGES = {
  en: "en",
  ja: "ja",
  ko: "ko",
  zh: "zh",
};

export const FONT_FAMILY = {
  en: "Noto Sans",
  ja: "Noto Sans JP",
  ko: "Noto Sans KR",
  zh: "Noto Sans SC",
};

export const NAV_MENUS = ["home", "about", "planning", "retail", "directions"];

export const GOOGLE_MAP_API_KEY = "AIzaSyCkpGDnSJCJu0k6mjVHekfAqbfcm6Kup1E";

export const DRAWER_WIDTH = 240;
