import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { DRAWER_WIDTH, NAV_MENUS } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const LANGUAGE_MENUS = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "ja",
    label: "日本語",
  },
  {
    value: "ko",
    label: "한국어",
  },
  {
    value: "zh",
    label: "中文",
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },

  listItem: {
    marginBottom: 20,
  },
}));

const NavigationDrawer = ({ open, handleDrawerClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleRoute = (route) => {
    history.push(route);
  };

  const handleLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {NAV_MENUS.map((menu, index) => {
          const menuText = t(`nav.${menu}`);

          const openingIndex = menuText.indexOf("<");
          const closingIndex = menuText.indexOf(">");

          let parsedText = menuText;

          if (openingIndex > 0 && closingIndex > 0) {
            parsedText =
              menuText.slice(0, openingIndex) +
              " " +
              menuText.slice(closingIndex + 1);
          }

          const href = index === 0 ? "/" : `/${menu}`;

          return (
            <ListItem
              onClick={() => handleRoute(href)}
              button
              key={`drawer${menu}`}
              className={classes.listItem}
            >
              {parsedText}
            </ListItem>
          );
        })}
      </List>
      <Box style={{ textAlign: "center", marginBottom: 20 }}>
        <Typography>Language</Typography>
      </Box>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={1}
        style={{ textAlign: "center" }}
      >
        {LANGUAGE_MENUS.map((lang) => (
          <Grid key={lang.label} item xs={6}>
            <Button
              variant="text"
              style={{
                color: i18n.languages[0] === lang.value ? "#2441ab" : "black",
              }}
              onClick={() => handleLanguage(lang.value)}
            >
              {lang.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Drawer>
  );
};

export default NavigationDrawer;
