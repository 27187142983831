import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import background from "../../../assets/introduction_background.png";

import about from "../../../assets/about.png";
import about2x from "../../../assets/about@2x.png";
import about3x from "../../../assets/about@3x.png";

import motto from "../../../assets/motto.png";
import motto2x from "../../../assets/motto@2x.png";
import motto3x from "../../../assets/motto@3x.png";

import mottoBackground from "../../../assets/motto_background.png";

import SectionTitleImage from "../../common/components/SectionTitleImage";

const useStyles = makeStyles((theme) => ({
  aboutSection: {
    position: "relative",
    paddingTop: "10%",
    paddingBottom: "10%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "15%",
      paddingBottom: "15%",
    },
  },
  backgroundImg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },

  aboutContainer: {
    marginTop: 70,
    backgroundColor: "white",
    padding: "70px 155px",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
      padding: "40px 30px",
    },
  },

  aboutTitle: {
    fontSize: 24,
    color: "black",
    fontWeight: "bold",
    lineHeight: 1.4,
    marginBottom: 26,
  },

  aboutBody: {
    fontSize: 20,
    lineHeight: 2,
    color: "black",
    marginBottom: 24,
  },

  aboutFooter: {
    fontSize: 20,
    lineHeight: 2,
    color: "black",
    fontWeight: "bold",
  },

  mottoSection: {
    position: "relative",
    paddingTop: "10%",
    paddingBottom: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: "15%",
      paddingBottom: 0,
    },
  },

  mottoContainer: {
    textAlign: "left",
    marginTop: 70,

    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
  },

  mottoTitle: {
    fontSize: 36,
    lineHeight: 1.3,
    color: "#2441ab",
    marginBottom: 60,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      marginBottom: 40,
    },
  },

  mottoBody: {
    textAlign: "left",
    fontSize: 20,
    lineHeight: 2,
    color: "black",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const Introduction = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Container maxWidth="xl" className={classes.aboutSection}>
        <img src={background} className={classes.backgroundImg} />
        <Container>
          <SectionTitleImage
            img={about}
            srcSet={`${about2x} 2x, ${about3x} 3x`}
          />
          <Box className={classes.aboutContainer}>
            <Typography className={classes.aboutTitle}>
              {t("introduction.about.title")}
            </Typography>
            <Typography className={classes.aboutBody}>
              {t("introduction.about.body1")}
            </Typography>
            <Typography className={classes.aboutFooter}>
              {t("introduction.about.body2")}
            </Typography>
          </Box>
        </Container>
      </Container>
      <Container maxWidth="xl" className={classes.mottoSection}>
        <Container>
          <SectionTitleImage
            img={motto}
            srcSet={`${motto2x} 2x, ${motto3x} 3x`}
          />
          <Box className={classes.mottoContainer}>
            <Typography className={classes.mottoTitle}>
              {t("introduction.motto.title")}
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography className={classes.mottoBody}>
                {t("introduction.motto.body1")}
              </Typography>
              <br />
              <Typography className={classes.mottoBody}>
                {t("introduction.motto.body2")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ paddingTop: !matches ? 100 : 60 }}
            >
              <img
                src={mottoBackground}
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: 0.4,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default Introduction;
