import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import resource1 from "../../../assets/P500_2.jpg";
import resource2 from "../../../assets/2-244.png";
import resource3 from "../../../assets/3-93.png";

import arrowRight from "../../../assets/arrow_right.png";
import arrowLeft from "../../../assets/arrow_left.png";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const SERVICES_IMAGES = [resource1, resource2, resource3];

const useStyles = makeStyles((theme) => ({
  servicesSection: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "4%",
    paddingBottom: "4%",
    height: 1100,

    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10%",
      paddingBottom: "10%",
      height: 650,
    },
  },
  serviceTitle: {
    fontSize: 22,
    lineHeight: 1.1,
    color: "black",
    fontFamily: "Noto Sans",
    letterSpacing: -0.83,
    marginBottom: 30,

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  serviceMainTitle: {
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  },
  serviceTitleText: {
    fontSize: (props) => (props.language === "en" ? 38 : 42),
    lineHeight: 1.5,
    letterSpacing: -0.25,
    color: "black",
    fontWeight: "bold",

    [theme.breakpoints.down("md")]: {
      fontSize: (props) => (props.language === "en" ? 20 : 24),
    },
  },

  serviceImageSlide: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  serviceSlideTitle: {
    fontSize: 30,
    fontWeight: "bold",
    lineHeight: 1.2,
    letterSpacing: -0.92,
    color: "#2441ab",
    marginBottom: 16,

    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  serviceSlideBody: {
    fontSize: 20,
    lineHeight: 1.4,
    color: "black",
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },

  arrowButton: {
    width: 60,
    height: 60,
    [theme.breakpoints.down("md")]: {
      width: 30,
      height: 30,
    },
  },
}));

const HomeServices1 = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Container maxWidth="xl" className={classes.servicesSection}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item className={classes.serviceTitle}>
          <Typography>{t("home.services.title1")}</Typography>
        </Grid>
        <Grid item className={classes.serviceMainTitle}>
          <Typography className={classes.serviceTitleText}>
            {t("home.services.title2")}
          </Typography>
        </Grid>
      </Grid>
      <Container maxWidth="lg" style={{ position: "relative" }}>
        <Container maxWidth="md">
          <SwipeableViews
            index={currentIndex}
            onChangeIndex={(index) => setCurrentIndex(index)}
          >
            {SERVICES_IMAGES.map((image, index) => (
              <Box key={image} className={classes.serviceImageSlide}>
                <img
                  src={image}
                  style={{
                    marginBottom: 60,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ))}
          </SwipeableViews>

          <Typography className={classes.serviceSlideTitle}>
            {t(`home.services.imgTitle${currentIndex + 1}`)}
          </Typography>
          <Typography className={classes.serviceSlideBody}>
            {t(`home.services.imgBody${currentIndex + 1}`)}
          </Typography>
        </Container>
        {!matches && (
          <>
            <Button
              style={{
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-100%)",
                opacity: currentIndex === SERVICES_IMAGES.length - 1 ? 0.2 : 1,
              }}
              disabled={currentIndex === SERVICES_IMAGES.length - 1}
              onClick={() => setCurrentIndex(currentIndex + 1)}
            >
              <img src={arrowRight} />
            </Button>

            <Button
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "translateY(-100%)",
                opacity: currentIndex === 0 ? 0.2 : 1,
              }}
              disabled={currentIndex === 0}
              onClick={() => setCurrentIndex(currentIndex - 1)}
            >
              <img src={arrowLeft} />
            </Button>
          </>
        )}
      </Container>
    </Container>
  );
};

export default HomeServices1;
