import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  titleImg: {
    maxWidth: "60%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
}));

const SectionTitleImage = ({ img, srcSet, containerClass }) => {
  const classes = useStyles();
  return (
    <Box className={containerClass}>
      <img className={classes.titleImg} src={img} srcSet={srcSet} />
    </Box>
  );
};

export default SectionTitleImage;
