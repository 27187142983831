import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  moreButton: {
    width: 226,
    height: 50,
    textAlign: "center",
    backgroundColor: "#2441ab",
    color: "white",
    borderRadius: 0,
    border: "none",
    fontSize: 16,

    [theme.breakpoints.down("md")]: {
      width: 150,
      height: 40,
    },
  },
}));

const MoreButton = ({ containerStyle, onClick, href }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center" style={containerStyle}>
      <Button className={classes.moreButton} onClick={onClick} href={href}>
        MORE
      </Button>
    </Grid>
  );
};

export default MoreButton;
