import React from "react";

import HomeBrandLogo from "./HomeBrandLogo";
import HomeMotto from "./HomeMotto";
import HomeAboutUs from "./HomeAboutUs";
import HomeServices1 from "./HomeServices1";
import HomeServices2 from "./HomeServices2";
import HomeContact from "./HomeContact";

const Home = () => {
  return (
    <>
      <HomeBrandLogo />
      <HomeMotto />
      <HomeAboutUs />
      <HomeServices1 />
      <HomeServices2 />
      <HomeContact />
    </>
  );
};

export default Home;
