import {
  Container,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import MoreButton from "../common/MoreButton";

import service2_1 from "../../../assets/service2_1.png";
import service2_2 from "../../../assets/service2_2.png";
import service2_3 from "../../../assets/service2_3.png";
import service2_4 from "../../../assets/service2_4.png";

const SERVICES_IMAGES_2 = [service2_1, service2_2, service2_3, service2_4];

const useStyles = makeStyles((theme) => ({
  servicesSecondSection: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "4%",
    paddingBottom: "4%",
    backgroundColor: "#fcfbff",

    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
  },
  servicesSecondTitle: {
    fontSize: (props) => (props.language === "en" ? 38 : 42),
    lineHeight: 1.5,
    letterSpacing: -0.25,
    color: "black",
    fontWeight: "bold",

    [theme.breakpoints.down("md")]: {
      fontSize: (props) => (props.language === "en" ? 20 : 24),
    },
  },
  servicesSecondSectionTitle: {
    marginBottom: 97,

    [theme.breakpoints.down("md")]: {
      marginBottom: 60,
    },
  },

  servicesSecondSectionTitleText: {
    marginBottom: 30,
    fontSize: (props) => (props.language === "en" ? 38 : 42),
    lineHeight: 1.5,
    letterSpacing: -0.25,
    color: "black",
    fontWeight: "bold",

    [theme.breakpoints.down("md")]: {
      fontSize: (props) => (props.language === "en" ? 20 : 24),
    },
  },
  servicesSecondSectionBody: {
    fontSize: 16,
    lineHeight: 2,
    color: "black",
  },
  servicesSecondImgContainer: {
    marginBottom: 76,
    [theme.breakpoints.down("md")]: {
      marginBottom: 36,
    },
  },
  servicesSecondImg: {
    width: "100%",
    marginBottom: 28,
  },
}));

const HomeServices2 = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();
  return (
    <Container maxWidth="xl" className={classes.servicesSecondSection}>
      <Container>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid xs={10} item className={classes.servicesSecondTitle}>
            <Typography align="left">{t("home.services.title1")}</Typography>
          </Grid>
          <Grid xs={10} item className={classes.servicesSecondSectionTitle}>
            <Typography
              className={classes.servicesSecondSectionTitleText}
              align="left"
            >
              {t("home.services.title3")}
            </Typography>
            <Typography
              align="left"
              className={classes.servicesSecondSectionBody}
            >
              {t("home.services.body1")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center" spacing={4}>
          {SERVICES_IMAGES_2.map((image, index) => (
            <Grid
              item
              xs={10}
              lg={6}
              key={image}
              className={classes.servicesSecondImgContainer}
            >
              <img src={image} className={classes.servicesSecondImg} />
              <Typography style={{ fontWeight: "bold" }}>
                {t(`home.services.body2_${index + 1}`)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <MoreButton href="/retail" />
      </Container>
    </Container>
  );
};

export default HomeServices2;
