import {
  Box,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GOOGLE_MAP_API_KEY } from "../../utils/constants";

import telIcon from "../../../assets/tel_icon.png";
import faxIcon from "../../../assets/fax_icon.png";
import emailIcon from "../../../assets/email_icon.png";
import clsx from "clsx";
import MoreButton from "../common/MoreButton";

const useStyles = makeStyles((theme) => ({
  contactSection: {
    paddingTop: "4%",
    paddingBottom: "13%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingBottom: "30%",
    },
  },

  contactTitle: {
    fontSize: 22,
    lineHeight: 1.1,
    color: "black",
    fontFamily: "Noto Sans",
    letterSpacing: -0.83,
    marginBottom: 30,

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  contactMainTitle: {
    marginBottom: 74,
    [theme.breakpoints.down("md")]: {
      marginBottom: 60,
    },
  },
  contactTitleText: {
    fontSize: (props) => (props.language === "en" ? 38 : 42),
    lineHeight: 1.5,
    letterSpacing: -0.25,
    color: "black",
    fontWeight: "bold",

    [theme.breakpoints.down("md")]: {
      fontSize: (props) => (props.language === "en" ? 20 : 24),
    },
  },

  mapContainer: {
    width: "100%",
    height: 592,

    marginBottom: 74,

    [theme.breakpoints.down("md")]: {
      height: 400,
    },
    [theme.breakpoints.down("sm")]: {
      height: 300,
      marginBottom: 50,
    },
  },

  addressLeft: {
    borderRight: "1px solid #e3e3e3",

    [theme.breakpoints.down("md")]: {
      borderRight: "none",
    },
  },

  contactText: {
    fontSize: 20,
    color: "black",

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  zipCode: {
    lineHeight: 1.5,
    letterSpacing: 2.1,
    marginBottom: 35,
  },

  direction: {
    lineHEight: 1.6,
  },

  addressRight: {
    height: "100%",
  },

  contactIcons: { marginRight: 10 },
  contactType: {
    lineHeight: 1.5,
  },

  tel: {
    lineHeight: 1.5,
    letterSpacing: 2.1,
  },

  email: {
    lineHeight: 1.5,
    letterSpacing: 1.6,
  },

  addressRow: {
    marginBottom: 30,
  },
  iconContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const HomeContact = ({
  showTitle = true,
  title,
  showMoreButton = true,
  button,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();

  const mapRef = useRef();

  const [zoom, setZoom] = useState(17);

  const [bootstrapURLKey, setBootstrapURLKey] = useState({
    key: GOOGLE_MAP_API_KEY,
    language: i18n.languages[0],
    region: "jp",
  });

  useEffect(() => {
    if (i18n.languages[0] !== bootstrapURLKey.language) {
      setBootstrapURLKey({
        key: GOOGLE_MAP_API_KEY,
        language: i18n.languages[0],
        region: "jp",
      });
      console.log("here");

      window.location.reload();
    }
  }, [i18n.languages[0]]);

  const renderMarker = ({ map, maps }) => {
    let marker = new maps.Marker({
      position: {
        lat: 35.728766,
        lng: 139.776877,
      },
      map,
    });
  };

  return (
    <Container className={classes.contactSection}>
      <Grid container direction="column" justify="center" alignItems="center">
        {showTitle ? (
          <>
            <Grid item className={classes.contactTitle}>
              <Typography>{t("home.contact.title1")}</Typography>
            </Grid>
            <Grid item className={classes.contactMainTitle}>
              <Typography className={classes.contactTitleText}>
                {t("home.contact.title2")}
              </Typography>
            </Grid>
          </>
        ) : (
          title
        )}
        <Grid item className={classes.mapContainer}>
          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={bootstrapURLKey}
            defaultCenter={{
              lat: 35.728766,
              lng: 139.776877,
            }}
            defaultZoom={zoom}
            onZoomAnimationStart={(value) => setZoom(value)}
            onGoogleApiLoaded={renderMarker}
          ></GoogleMapReact>
        </Grid>
        {showMoreButton ? (
          <MoreButton
            href="/directions"
            containerStyle={{ marginBottom: 80 }}
          />
        ) : (
          button
        )}
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ textAlign: "left" }}
        spacing={4}
      >
        <Grid item xs={10} lg={7} className={classes.addressLeft}>
          <Typography className={clsx(classes.contactText, classes.zipCode)}>
            {t("home.contact.address1")}
          </Typography>

          <Typography className={clsx(classes.contactText, classes.direction)}>
            {t("home.contact.address2")}
          </Typography>
        </Grid>
        <Grid item xs={10} lg={5} className={classes.addressRight}>
          <Grid container className={classes.addressRow} spacing={1}>
            <Grid item xs={10} lg={5}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className={classes.iconContainer}>
                  <img src={telIcon} className={classes.contactIcons} />
                  <Typography
                    className={clsx(classes.contactText, classes.contactType)}
                  >
                    Tel.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Link
                  href="tel:+813-6868-7138"
                  className={clsx(classes.contactText, classes.tel)}
                >
                  {t("home.contact.tel")}
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container className={classes.addressRow}>
            <Grid item xs={10} lg={5}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className={classes.iconContainer}>
                  <img src={faxIcon} className={classes.contactIcons} />
                  <Typography
                    className={clsx(classes.contactText, classes.contactType)}
                  >
                    Fax.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Link className={clsx(classes.contactText, classes.tel)}>
                  {t("home.contact.fax")}
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10} lg={5}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className={classes.iconContainer}>
                  <img src={emailIcon} className={classes.contactIcons} />
                  <Typography
                    className={clsx(classes.contactText, classes.contactType)}
                  >
                    E-mail
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Link
                  href="mailto:costumeand@gmail.com"
                  className={clsx(classes.contactText, classes.email)}
                >
                  {t("home.contact.email")}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeContact;
