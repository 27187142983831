import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { CircularProgress } from "@material-ui/core";
import "react-dropdown/style.css";
import "@brainhubeu/react-carousel/lib/style.css";

ReactDOM.render(
  <React.Fragment>
    <Suspense
      fallback={
        <div
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
