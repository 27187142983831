import {
  Box,
  Button,
  Container,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay, virtualize } from "react-swipeable-views-utils";

import homeLogo from "../../../assets/home_logo.png";
import homeLogo2x from "../../../assets/home_logo@2x.png";
import homeLogo3x from "../../../assets/home_logo@3x.png";
import homeLogoKr from "../../../assets/home_logo_kr.png";
import homeLogoKr2x from "../../../assets/home_logo_kr@2x.png";
import homeLogoKr3x from "../../../assets/home_logo_kr@3x.png";
import homeLogoEn from "../../../assets/home_logo_en.png";
import homeLogoEn2x from "../../../assets/home_logo_en@2x.png";
import homeLogoEn3x from "../../../assets/home_logo_en@3x.png";
import homeLogoCh from "../../../assets/home_logo_ch.png";
import homeLogoCh2x from "../../../assets/home_logo_ch@2x.png";
import homeLogoCh3x from "../../../assets/home_logo_ch@3x.png";

import home1 from "../../../assets/home1.png";
import home2 from "../../../assets/home2.jpg";
import home3 from "../../../assets/home3.png";
import home4 from "../../../assets/home4.png";
import home5 from "../../../assets/home5.png";

import arrowRight from "../../../assets/arrow_right.png";
import arrowLeft from "../../../assets/arrow_left.png";
import { useTranslation } from "react-i18next";

const TOP_IMAGES = [home1, home2, home3, home4, home5];

const HOME_LOGO = {
  en: {
    src: homeLogoEn,
    srcSet: `${homeLogoEn2x} 2x, ${homeLogoEn3x} 3x`,
  },

  ko: {
    src: homeLogoKr,
    srcSet: `${homeLogoKr2x} 2x, ${homeLogoKr3x} 3x`,
  },
  zh: {
    src: homeLogoCh,
    srcSet: `${homeLogoCh2x} 2x, ${homeLogoCh3x} 3x`,
  },
  ja: {
    src: homeLogo,
    srcSet: `${homeLogo2x} 2x, ${homeLogo3x} 3x`,
  },
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  mainCarousel: {
    position: "relative",
    marginTop: "5%",
  },

  mainSlide: {
    width: "100%",
    height: 700,
    alignSelf: "center",

    [theme.breakpoints.down("lg")]: {
      height: 500,
    },
    [theme.breakpoints.down("md")]: {
      height: 450,
    },
    [theme.breakpoints.down("sm")]: {
      height: 380,
    },
    [theme.breakpoints.down("xs")]: {
      height: 300,
    },
  },

  homeLogoContainer: {
    position: "absolute",
    top: "100%",
    left: "50%",
    width: "60%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  homeLogo: {
    width: "100%",
  },

  nextButton: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(50%, -50%)",
    width: 60,
    height: 60,

    [theme.breakpoints.down("md")]: {
      visibility: "hidden",
    },
  },
  prevButton: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 60,
    height: 60,

    [theme.breakpoints.down("md")]: {
      visibility: "hidden",
    },
  },
}));

const HomeBrandLogo = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ language: i18n.languages[0] });
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIndexChange = (index) => {
    setCurrentIndex(index);
  };

  const nextSlide = () => {
    if (currentIndex < TOP_IMAGES.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(TOP_IMAGES.length - 1);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box className={classes.mainCarousel}>
        <AutoPlaySwipeableViews
          index={currentIndex}
          onChangeIndex={handleIndexChange}
          enableMouseEvents
        >
          {TOP_IMAGES.map((image) => (
            <div key={image} className={classes.mainSlide}>
              <img
                draggable={false}
                src={image}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {!matches && (
          <>
            <Button className={classes.nextButton} onClick={nextSlide}>
              <img src={arrowRight} />
            </Button>
            <Button className={classes.prevButton} onClick={prevSlide}>
              <img src={arrowLeft} />
            </Button>
          </>
        )}
        <Box className={classes.homeLogoContainer}>
          <img
            src={HOME_LOGO[i18n.languages[0]].src}
            srcSet={HOME_LOGO[i18n.languages[0]].srcSet}
            className={classes.homeLogo}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default HomeBrandLogo;
